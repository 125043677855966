 .home2-footer {
   padding: 1vh;
   font-size: 20px;
   line-height: 24px;
   background-color: #B3C8D8;
   margin-top: 4px;
   width: 100%;
   text-align: left;
   color: black;
   padding-left: 10px;
   padding-right: 10px;
 }

 @media (max-width: 1280px) {
   .home2-footer {
     margin-top: 3px;
     padding: 6px;
     font-size: 16px;
     line-height: 20px;
     width: 100%;
   }
 }

 .home2-footer-full {
   padding: 6px;
   font-size: 16px;
   line-height: 20px;
   background-color: #B3C8D8;
   position: absolute;
   top: calc(100% - 110px);
   height: 110px;
   width: 50%;
   left: 50%;
   text-align: left;
   color:black;
   padding-left: 10px;
   padding-right: 10px;
 }

 .home2-footer-copyright {
   padding-top: 10px;
   font-size: 12px;
 }


 @media (max-width: 1280px) {
   .home2-footer-copyright {
     padding-top: 4px;
     font-size: 10px;
   }
 }

 .home2-footer-terms {
   padding-top: 0px;
   font-size: 12px;
   line-height: 12px;
 }


 @media (max-width: 1280px) {
   .home2-footer-terms {
     font-size: 10px;
     line-height: 10px;
   }
 }


 .home2-footer-container {
   font-family: "Open Sans", sans-serif;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
 }

 .home2-footer-fuw-logo {
   height: 100px;
   width: auto;
 }

 @media (max-width: 1280px) {
   .home2-footer-fuw-logo {
     height: 100px;
     width: auto;
   }
 }