 .whirloff-footer-copyright {
   padding-top: 10px;
   font-size: 12px;
 }


 @media (max-width: 1280px) {
   .whirloff-footer-copyright {
     padding-top: 4px;
     font-size: 10px;
   }
 }

 .whirloff-footer-terms {
   padding-top: 0px;
   font-size: 12px;
   line-height: 12px;
 }


 @media (max-width: 1280px) {
   .whirloff-footer-terms {
     font-size: 10px;
     line-height: 10px;
   }
 }

 .whirloff-footer-container {
   font-family: "Open Sans", sans-serif;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   width: 100%;
 }

 img.whirloff-footer-fuw-logo {
   height: 100px;
   width: auto;
 }

 @media (max-width: 1280px) {
   img.whirloff-footer-fuw-logo {
     height: 100px;
     width: auto;
   }
 }