.home2-full body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}

.home2-scroll {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: black;
  align-items: center;
  text-align: center;
  background-color: #FFFFFF;
  width: 100%;
  justify-content: space-between;
}

.home2-scroll-full-large {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: black;
  align-items: center;
  text-align: center;
  background-color: #FFFFFF;
  width: 100%;
  overflow-x:hidden;
  overflow-y:auto;
  top:146px;
  height: calc(100vh - 266px);
  width: 450px;
}

.home2-scroll-full-xlarge {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: black;
  align-items: center;
  text-align: center;
  background-color: #FFFFFF;
  width: 100%;
  overflow-x:hidden;
  overflow-y:auto;
  top:146px;
  height: calc(100vh - 264px);
  width: 730px;
}

.home2-content-right-large {
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  position: fixed;
  width: 450px;
  top: 76px;
  height: calc(100vh - 76px);
  left: calc(100% - 450px);
}

.home2-content-right-xlarge {
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  position: fixed;
  width: 730px;
  top: 76px;
  height: calc(100vh - 76px);
  left: calc(100% - 730px);
}


.home2-body {
  justify-content: space-between;
  align-items: left;
  background-color: #FFFFFF;
  width: 1280px;
  margin-top: 6px;
  margin-bottom: 6px;
}


.home2-header-mobile {
  background-color: #0f4c81;
  width: 100%;
  height: 76px;
}

.home2-header-full {
  position: fixed;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 76px;
  background-color: #0f4c81;
}

.home2-whirloff-banner-full {
  font-size: 16px;
  line-height: 20px;
  position: absolute;
  top: calc(100% - 110px);
  height: 110px;
  width: 50%;
  text-align: center;
}

.home2-map-full-xlarge {
  position: fixed;
  left: 0%;
  top: 76px;
  width: calc(100% - 730px);
  height: calc(100vh - 76px);
  border: none;
}

.home2-map-full-large {
  position: fixed;
  left: 0%;
  top: 76px;
  width: calc(100% - 450px);
  height: calc(100vh - 76px);
  border: none;
}

.home2-windy-map-full {
  position: fixed;
  left: 0%;
  top: 108px;
  width: calc(100% - 730px);
  height: calc(100vh - 108px);
  border: none;
}


.home2-map-bounds-links-full {
  width: 100%;
  height: 32px;
  background-color: #092f4f;
}

.home2-map-inline {
  margin-top: 2px;
  width: 100%;
  height: calc(100vh * 0.5);
}

.home2-whirloff-inline {
  margin-top: 2px;
  width: 100%;
  padding: 0px;
  margin-bottom: 1px;
}

.home2-molen-table {
  width: 100%;
}

.home2-molen-table-full {
  width: 100%;
  padding-left: 4px;
  padding-right: 4px;
}

.home2-lower {
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  background-color: #505050;
  padding: 4px;
}

.home2-text {
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
}

.home2-text a {
  color: blue;
}

.home2-text a:visited {
  color: blue;
}