.dw4-graph {
    position:fixed;
    top: 12vh;
    left: 2vw;
    width: 96vw;
    height:86vh;
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    color:#000000;
    padding: 2vw;
}

.dw4-graph-explanation {
    background-color: #0c4071;
    color: white;
    padding: 1.0vw;
    margin-top:1vh;
    margin-left:2vw;
    display: flex;
    flex-direction: row;
}


.dw4-graph-explanation-left {
    margin-top: 0.4vw;
    width:66%;
}

.dw4-graph-explanation-right {
    width: 34%;
    background-color: #f0f0f0;
    color: black;
    padding: 0.6vw;
}