.weather-station-row {
    width: 100%;
    min-width: 140px;
    height:70px;
    background-color: #0f4c81;
    color: #FFFFFF;
    padding:6px;
    margin-top: 10px;
    display: flex;
    flex-direction: row;
}

.ws-row-col1 {
    width: 15%;
}

.ws-row-col2 {
    width: 53%;
}

.ws-row-col4 {
    width: 18%;
}

.ws-row-col5 {
    width: 20%;
}
