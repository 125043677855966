
  .alert-container {
    width:100% - 12px;
    padding:4px;
    margin:6px;
    background-color: #F00000;
    display:flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
}

@media (max-width: 300px) {
    .alert-container {
        padding: 2px;
      }
}

.alert-icon {
    height: 64px;
     width: 64px;
}

.alert-list {
  display:flex;
  flex-direction: column;
}
