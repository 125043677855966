.home2-list-header {
  width: 100%;
  color: white;
  text-align: center;
}

.home2-list-header-full {
  width: calc(100% - 8px);
  color: white;
  text-align: center;
  height:70px;
  margin: 4px;
}

@media (max-width: 1280px) {
  .home2-list-header {
    margin-top: 2px;
  }
}
