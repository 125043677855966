table#alert-detail-table {
    font-family: "Open Sans";
    font-size: 12px;
    width:100%;
}

#alert-detail-table td {
    padding-right:10px;
    padding-bottom:2px;
}

.long-text-cell {
    width: 442px;
    word-wrap: break-word;
}