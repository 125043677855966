.weather-forecast-panel-contents {
    display: flex;
    flex-direction: column;
    width:100%;
}

.weather-forecast-table-gust {
    background-color: #000000;
    color:#ffff00;
    padding:2px;
}

table#weather-forecast-table {
    height:12px;
    font-family: "Open Sans";
    font-size: 12px;
    width:100%;
}

#weather-forecast-table tr:nth-child(even) {background-color: #092f4f;}
#weather-forecast-table tr:nth-child(odd) {background-color: #0f4c81;}

#weather-forecast-table tr th {
    height: 33px;
    background-color: #092f4f;
    font-family: "Roboto";
    font-weight:normal;
    font-size: 14px;
    padding-left:2px;
    padding-right:2px;
    text-align:center;
}

#weather-forecast-table tr td {
    height: 33px;
    border: 2px solid #092f4f;
    padding-left:5px;
    padding-right:5px;
    font-family: "Open Sans";
    font-size: 14px;
    padding-top: 0px;
    padding-bottom: 0px;
}

@media (max-width: 300px) {
    #weather-forecast-table tr td {
        height: 29px;
        border: 1px solid #092f4f;
        padding-left:1px;
        padding-right:1px;
        font-family: "Open Sans";
        font-size: 13px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
  }

@media (min-width: 560px) and (max-width: 719px) {
    #weather-forecast-table tr td {
        height: 29px;
        border: 1px solid #092f4f;
        padding-left:1px;
        padding-right:1px;
        font-family: "Open Sans";
        font-size: 13px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
  }

