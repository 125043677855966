.monitoring-status-panel {
  padding: 8px;
  font-size: 20px;
  line-height: 24px;
  height:100%;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 992px) {
  .monitoring-status-panel {
    padding: 6px;
    font-size: 16px;
    line-height: 20px;
    height:auto;
    width:auto;
  }
}