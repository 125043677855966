  .whirloff-headline-stats-container {
    display: flex;
    flex-direction: column;
    color: #000000;
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    background-color: #303030;
    color: #FFFFFF;
    margin: 8px;
    font-size: 1.3em;
  }

  .whirloff-headline-stats-title {
    background-color: #644109;
    color: #FFFFFF;
    margin-bottom: 12px;
    font-size: 1.5em;
    line-height: 1.2em;
    font-weight: bold;
    width: 100%;
    padding: 4px;
    text-align: center;
  }

  .whirloff-grand-total {
    width: calc(100% - 20px);
    background-color: #202020;
    padding: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 6px;
  }

  .whirloff-stats-row {
    width: calc(100% - 20px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 4px;
  }

  .whirloff-stat-left {
    width: calc(50% - 3px);
    background-color: #202020;
    padding: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .whirloff-stat-right {
    width: calc(50% - 3px);
    background-color: #202020;
    padding: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
  }

  .whirloff-headline-stats-major-stat {
    font-size: 2.2em;
    font-family: "Roboto";
    font-weight: bold;
  }

  .whirloff-headline-stats-secondary-title {
    font-weight: bold;
  }

  .whirloff-headline-stats-secondary-detail {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }


  @media (max-width: 1200px) {

    .whirloff-headline-stats-container {
      padding: 4px;
    }

    .whirloff-grand-total {
      font-size: 0.8em;
      line-height: 2em;
      font-family: "Roboto";
      font-weight: bold;
      height: 100%;
      width: calc(100%-12px);
      padding: 2px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      margin-left: 0px;
      margin-right: 0px;
      margin-bottom: 0px;

    }


  }