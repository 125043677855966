.dw-navigation {
    position:fixed;
    width: 94vw;
    height: 8vh;
    margin-left: 3vw;
    margin-right: 3vw;
    margin-top: 2vh;
    font-size: 1.5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dw-navigation-heading {
    display: flex;
    flex-direction: row;
    width:25vw;
}

.dw-navigation-heading-left {
    margin-right:0.5vw;
}

.dw-navigation-heading-right-upper {
    font-family: "Raleway";
    font-size: 3.2vh;
    font-weight: bold;
    line-height: 3.4vh;
}

.dw-navigation-heading-right-lower {
    padding-left:0.3vw
}

.dw-navigation-button {
    background-color: #092f4f;
    width:14vw;
    text-align: center;
    border-color: white;
    height: 8vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:5px;
    padding: 0.6vh;
    margin-right: 0.2vw;
    font-size: 1.4vw;
}

.dw-navigation-button-2 {
    background-color: #092f4f;
    width:14vw;
    text-align: center;
    border-color: white;
    height: 8vh;
}

.dw-navigation-button-3 {
    background-color: #092f4f;
    width:14vw;
    text-align: center;
    border-color: white;
    height: 8vh;
}

.dw-navigation-button-4 {
    background-color: #092f4f;
    width:14vw;
    text-align: center;
    border-color: white;
    height: 8vh;
}

.dw-navigation-button-5 {
    background-color: #092f4f;
    width:14vw;
    text-align: center;
    border-color: white;
    height: 8vh;
}