.internal-temp-panel {
    width: 50%;
    min-width: 140px;
    background-color: #F0F0f0;
    color: #000000;
    padding:10px;
    margin-right:10px;
}


@media (max-width: 600px) {
    .internal-temp-panel {
        min-width: 140;
        padding: 4px;
        margin-right: 6px;
    }
}

.external-temp-panel {
    width: 50%;
    min-width: 180;
    background-color: #F0F0F0;
    color: #000000;
    padding: 10px;
}

@media (max-width: 600px) {
    .external-temp-panel {
        min-width: 140;
        padding: 4px;
    }
  }
