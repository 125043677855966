  .whirloff-header-container {
    display: flex;
    flex-direction: row;
    padding: 8px;
    width: 100%;
    flex: 0 0 100%;
    align-items:flex-start;
  }

  .whirloff-header-logo {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .whirloff-header-right {
    display: flex;
    flex-direction: column;
    width: calc(100% - 200px);
    height: 180px;
    margin-right: 10px;
    margin-left: 0px;
    line-height: 1.5em;
    font-size: 2.2em;
    font-family: "Raleway", "Open Sans", sans-serif;
    text-align: center;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .whirloff-header-right-tablet {
    display: flex;
    flex-direction: row;
    width: calc(100% - 200px);
    height: 100%;
    margin-right: 10px;
    margin-left: 30px;
    line-height: 1.6em;
    font-size: 2.2em;
    font-family: "Raleway", "Open Sans", sans-serif;
    text-align: center;
    color: #FFFFFF;
    align-items: center;
    justify-content: space-evenly;
    padding: 10px;
  }

  .whirloff-header-mobile {
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px);
    height: 100%;
    margin-right: 16px;
    margin-left: 10px;
    line-height: 1.6em;
    font-size: 1.5em;
    font-family: "Raleway", "Open Sans", sans-serif;
    text-align: center;
    color: #FFFFFF;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .whirloff-header-right-tablet-data-sources {
    font-size: 1.1em;
  }


  .whirloff-header-data-sources {
    font-size: 0.6em;
    line-height: 1.2em;
    font-family: "Roboto"
  }

  .whirloff-header-year-line {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding: 10px;
  }

  .whirloff-header-beta {
    background-color: #001070;
    color: white;
    font-size: 0.7em;
    line-height: 0.5em;
    font-weight: bold;
    padding: 10px;
  }

  @media (max-width: 576px) {

    .whirloff-header-container {
      padding: 6px;
    }
  
    .whirloff-header-right {
      width: calc(100% - 120px);
      line-height: 20px;
      font-size: 16px;
      font-family: "Raleway", "Open Sans", sans-serif;
    }

    .whirloff-header-data-sources {
      display: none;
    }

 }

 .whirloff-header-links {
  display: flex;
  flex-direction: row;
  margin-top: 2px;
 }

 /* Style the links inside the navigation bar */
 .whirloff-header-link a {
  float: left;
  display: block;
  color: #ffffff;
  text-align: center;
  background-color: #001070;
  padding: 4px 6px 4px 6px;
}

.whirloff-header-link a:link {
  color: #ffffff;
}

.whirloff-header-link a:visited {
  color: #ffffff;
}

/* Change the color of links on hover */
.whirloff-header-link a:hover {
  background-color: #009000;
  color: black;
}
