.header-container {
    font-family: "Raleway";
    display:flex;
    flex-direction: row;
    align-items: center;
    width:100%;
    max-width:700px;
    max-height:72px;
    padding:6px;
}

@media (max-width: 300px) {
    .header-container {
        padding: 2px;
      }
}

.header-icon {
    height: 64px;
     width: 64px;
}


.header-lower {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.header-title {
  padding-left: 10px;
}


@media (max-width: 300px) {
    .header-title {
        padding-left: 8px;
      }
}

@media (max-width: 300px) {
    .header-icon {
    height:48px;
    width: 48px;
    }
}
