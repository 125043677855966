
  .not-turning-container {
    width:100% - 12px;
    padding:6px;
    margin:6px;
    margin-top:12px;
    background-color: #F00000;
    display:flex;
    flex-direction: row;
    align-items: center;
}

@media (max-width: 300px) {
    .not-turning-container {
        padding: 2px;
        margin-top:6px;
      }
}

.not-turning-icon {
    height: 64px;
     width: 64px;
}

.not-turning-content {
  display:flex;
  flex-direction: column;
}
