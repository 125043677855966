table#response-to-wind-table {
    font-family: "Open Sans";
    font-size: 11px;
    width: 100%;
}

#response-to-wind-table tr th {
    font-weight:normal;
    font-size: 18px;
    padding-left:2px;
    padding-right:2px;
    text-align:center;
}

#response-to-wind-table tr td {
    padding-left:2px;
    padding-right:2px;
    font-family: "Open Sans";
    font-size: 11px;
    padding-top: 0px;
    padding-bottom: 0px;
}

#rotated-text {
    transform: rotate(270deg);
    font-family: "Open Sans";
    font-size: 18px;
    font-weight:bold;
    text-align: center;
}