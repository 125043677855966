.molen-list-item-panel {
  padding: 6px;
  font-family: "Open Sans", sans-serif;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
}

@media (max-width: 1400px) {
  .molen-list-item-panel {
    font-size: 11px;
    padding: 4px;
    line-height: 15px;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-top: 2px;
    margin-bottom: 0px;
  }
}

.molen-list-item-small-font {
  font-weight: normal;
  font-size: 12px;
}

@media (max-width: 1400px) {
  .molen-list-item-small-font {
    font-weight: normal;
    font-size: 11px;
  }
}

.molen-list-item-data-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.molen-list-item-webcam-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6px;
}

.molen-list-item-col-1-molen {
  width: 21.5%;
}

@media (max-width: 1400px) {
  .molen-list-item-col-1-molen {
    width: 29%;
  }
}

.molen-list-item-col-1-sensor-icons {
  display: none;
}

@media (max-width: 1400px) {
  .molen-list-item-col-1-sensor-icons {
    display: flex;
    margin-top: 4px;
  }
}


.molen-list-item-col-2-sensors {
  width: 15.5%;
}


@media (max-width: 1400px) {
  .molen-list-item-col-2-sensors {
    display: none;
  }
}


.molen-list-item-title {
  font-family: "Raleway", "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 17.5px;
}

@media (max-width: 1400px) {
  .molen-list-item-title {
    font-size: 13px;
  }
}


.molen-list-item-location-lower {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  margin-top: 2px;
  line-height: 15px;
  display: flex;
  flex-direction: row;
}

.molen-list-item-location-flag {
  flex-direction: column;
  margin-right: 6px;
}


.molen-list-item-location-detail {
  flex-direction: column;
}


@media (max-width: 1400px) {
  .molen-list-item-location-lower {
    display: none;
  }
}

.molen-list-item-subtitle {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.molen-list-item-subtitle-highlighted {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  color: white;
  background-color: #1A8BE8;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  padding-top: 0px;
  align-items: baseline;
  padding-bottom: 0px;
  line-height: 18px;
}


.molen-list-item-subtitle-color-reverse {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  color: #ffffff;
  background-color: #000000;
  padding-left: 3px;
  padding-right: 3px;
  margin-top: 1px;
}


.molen-list-item-subtitle-red {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 14px;
  color: #F00000;
}

@media (max-width: 1400px) {
  .molen-list-item-subtitle {
    font-size: 10.5px;
  }

  .molen-list-item-subtitle-color-reverse {
    font-size: 10.5px;
  }
}

@media (max-width: 1400px) {
  .molen-list-item-subtitle-red {
    font-size: 10px;
    line-height: 12px;
  }
}


.molen-list-item-sensor-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2px;
}

.molen-list-item-sensor-icon {
  margin-right: 4px;
}

.molen-list-item-webcam-tag {
  color: white;
  background-color: #1A8BE8;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 6px;
  font-size: 12px;
}

.molen-list-item-headline-stat {
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.molen-list-item-headline-stat-highlighted {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background-color: #1A8BE8;
  padding-left: 4px;
  padding-right: 4px;
  margin-right: 8px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  line-height: 18px;
}


@media (max-width: 1400px) {
  .molen-list-item-headline-stat {
    font-size: 15px;
  }

  .molen-list-item-headline-stat-highlighted {
    font-size: 15px;
  }
}

.molen-list-item-col-3-cap {
  width: 18%;
}

@media (max-width: 1400px) {
  .molen-list-item-col-3-cap {
    width: 27%;
  }
}

.molen-list-item-col-4-wind {
  width: 8%;
}

.molen-list-item-col-4a-wind-arrow {
  width: 10%;
}

.molen-list-item-col-4a-wind-arrow-small {
  display: none;
}

@media (max-width: 1400px) {
  .molen-list-item-col-4-wind {
    width: 16%;
  }

  .molen-list-item-col-4a-wind-arrow {
    display: none;
  }

  .molen-list-item-col-4a-wind-arrow-small {
    display: flex;
    width: 13%;
  }

}

.molen-list-wind-right {
  padding-left: 8px;
}

@media (max-width: 1400px) {
  .molen-list-wind-right {
    padding-left: 2px;
  }
}

.molen-list-item-col-5-multi {
  width: 27%;
}

@media (max-width: 576px) {
  .molen-list-item-col-5-multi {
    width: 16%;
  }

  .molen-list-item-col-5-sails {
    display: none;
  }

  .molen-list-item-col-5-fantail {
    display: none;
  }
}

.molen-list-item-col-5-wind-warnings {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 12px;
}

.molen-list-item-wind-warning-left {
  display: flex;
  flex-direction: column;
  align-items: baseline;
}

.molen-list-item-wind-warning-icon {
  height: 42px;
}

@media (max-width: 720px) {
  .molen-list-item-wind-warning-icon {
    height: 32px;
  }
}


.molen-list-item-weather-warning-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 14px;
}

.molen-list-item-weather-warning-text-red {
  color: #FF0000;
}

@media (max-width: 1400px) {
  .molen-list-item-weather-warning-text {
    display: none;
  }
}

.molen-list-item-wind-warning-micro-caption {
  display: none;
}

@media (max-width: 1400px) {
  .molen-list-item-wind-warning-micro-caption {
    font-family: 'Open Sans', sans-serif;
    font-size: 10.5px;
    line-height: 12px;
    display: flex;
  }
}


.molen-list-item-weather-warning-text-color-reverse {
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  color: #ffffff;
  background-color: #000000;
  padding-left: 3px;
  padding-right: 3px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-top: 1px;
}


.molen-list-wind {
  display: flex;
  flex-direction: row;
  align-items: center;
}