.dw2-smartmolen-content {
    position:fixed;
    top: 12vh;
    left: 2vw;
    width: 96vw;
    display: flex;
    flex-direction: row;
    color:#FFFFFF;
    transform: scale(var(--smartmolen-content-scale));
    transform-origin: top left;
}