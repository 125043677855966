.dw1-sails {
    position:fixed;
    background-color: #092f4f;
    top:86.5vh;
    left:4vw;
    width:30vw;
    height:8vw;
    align-items: center;
    display:flex;
    flex-direction: row;
}

.dw1-fantail {
    position:fixed;
    background-color: #092f4f;
    top:86.5vh;
    left:35vw;
    width:30vw;
    height:8vw;
    align-items: center;
    display:flex;
    flex-direction: row;
}

.dw1-powers {
    position:fixed;
    background-color: #092f4f;
    top:86.5vh;
    left:66vw;
    width:30vw;
    height:8vw;
    align-items: center;
    display:flex;
    flex-direction: row;
}

.dw1-lower-info {
    position:fixed;
    top:76vh;
    left:5vw;
    width:90vw;
    height:10vh;
    align-items: center;
    display:flex;
    flex-direction: row;
}

.dw1-tech-spec-left {
    width:8vw;
    align-items: center;
    display:flex;
    flex-direction: column;
    font-size: 1.2vw;
    font-family: "Roboto";
    background-color: #061835;
    height:100%;
    justify-content: center;
}

.dw1-tech-spec-right {
    width:22vw;
    padding-left: 0.8vw;
    padding-right:0.6vw;
}

.dw1-lower-info-type {
    width:19vw;
}

.dw1-lower-info-built {
    width:19vw;
}

.dw1-lower-info-purpose {
    width:19vw;
}

.dw1-lower-info-text {
    width:50vw;
}

.dw1-lower-info-data-point {
    font-family: "Roboto";
    font-size:3vh;
}

.dw1-media {
    position: fixed;
    top:12vh;
    left:4vw;
    width:92vw;
    height:64vh;
    object-fit: contain;
}

.dw1-media-image {
    position: fixed;
    top:12vh;
    left:4vw;
    width:93vw;
    height:64vh;
    object-fit: contain;
}

.dw1-media-overlay {
    position: fixed;
    top: 12vh;
    left:4vw;
    width:92vw;
    height:64vh;
    background-color: #FF0000;
    opacity: 0%;
}

.dw-webcam-view {
    width:100%;
    height:100%;
}
