
  .console-table-item-container {
    font-family: "Open Sans";
    display:flex;
    flex-direction: row;
    align-items:center;
    width:100%;
    padding:0.4vh;
    background-color: #E0E0E0;
    margin-top: 0.4vh;
    font-size: 0.9vh;
}

.console-table-item-col1 {
  width: 10vw;
}

.console-table-item-title {
  font-family: 'Raleway';
  font-weight: bold;
  font-size: 1.8vh;
}

.console-table-item-subtitle {
  font-family: 'Open Sans';
  font-size: 1.1vh;
}

.console-table-item-subtitle-red {
  font-family: 'Open Sans';
  color: #F00000;
  font-size: 1.1vh;
}

.console-headline-stat {
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 1.7vh;
}


.console-table-item-col2 {
  width: 7vw;  
}

.console-table-item-col3 {
  width: 7vw;
}

.console-table-item-col4 {
  width: 5vw;  
}

.console-table-wind {
  display:flex;
  flex-direction: row;
  align-items:center;
}

.console-table-wind-left {
  width: 3vw;
}