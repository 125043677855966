.home2-whirloff-banner-content {
    display: flex;
    flex-direction: row;
    font-size: 0.75em;
    justify-content:stretch;
    padding:6px;
    line-height: 1.0em;
    align-items: center;
    color: white;
    height: 100%;
    cursor: pointer; 
  }

  @media (max-width: 1280px) {
    .home2-whirloff-banner-content {
      font-size: 0.7em;
      line-height: 0.9em;
    }
  }  

  .home2-whirloff-banner-left {
    display: flex;
    flex-direction: column;
    width: 90px;
    margin-left: 2px;
  }

  .home2-whirloff-banner-right {
    display: flex;
    flex-direction: column;  
  }

  .home2-whirloff-banner-title {
    font-size: 1.4em;
    font-family: "Raleway";
    font-weight: bold;
    line-height: 1.2em;
  }

  .home2-whirloff-banner-headline-stat {
    font-size: 2em;
    font-weight: bold;
    font-family: "Roboto";
    line-height: 1.2em;
    margin-top: 2px;
  }

  .home2-whirloff-banner-lower-text {
    margin-top: 4px;
  }