.map-bounds-link-row {
  display:flex;
  flex-direction: row;
  justify-items:center;
  font-size: 14px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.map-bounds-link {
  padding: 6px;
  padding-left: 16px;
  padding-right: 16px;
  cursor: pointer;
}