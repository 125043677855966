
.table-large-numbers {
    font-family: "Roboto";
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    display: inline;
  }

.table-major-heading {
    font-family: "Roboto";
    font-size: 14px;
    line-height: 16px;
    display: inline;
  }

  .fantail-turns-panel-contents {
    display: flex;
    flex-direction: column;
    width:100%;
}

table#fantail-turns-table {
    height:60px;
    font-family: "Open Sans";
    font-size: 16px;
    line-height:18px;
    width:100%;
    margin-top:10px;
}


#fantail-turns-table tr th {
    font-family: "Roboto";
    font-weight:normal;
    font-size: 14px;
    line-height: 16px;
    padding-left:2px;
    padding-right:2px;
    text-align:center;
}


#fantail-turns-table tr td {
    height: 60px;
    border: 2px solid #ffffff;
    padding-left:5px;
    padding-right:5px;
    font-family: "Open Sans";
    font-size: 13px;
    padding-top: 0px;
    padding-bottom: 0px;
}

@media (max-width: 300px) {
    #fantail-turns-table tr td {
        height: 29px;
        border: 1px solid #092f4f;
        padding-left:1px;
        padding-right:1px;
        font-family: "Open Sans";
        font-size: 13px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
  }

@media (min-width: 560px) and (max-width: 719px) {
    #fantail-turns-table tr td {
        height: 29px;
        border: 1px solid #092f4f;
        padding-left:1px;
        padding-right:1px;
        font-family: "Open Sans";
        font-size: 13px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
  }

