  .home2-header-container {
    display: flex;
    flex-direction: row;
    color: white;
    padding: 12px;
    width: 100%;
    flex-wrap: wrap;
    flex: 0 0 100%;
    justify-content: space-between;
    align-items: center;
  }

  .home2-header-logo-and-name {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    min-width: 200px;
  }

  .home2-header-site-name {
    display: flex;
    flex-direction: column;
    align-items: left;
    text-align: left;
  }

  .home2-header-title {
    display: flex;
    margin-right: 10px;
    line-height: 28px;
    font-size: 24px;
    font-family: "Open Sans";
    font-weight: bold;
    text-align: left;
  }

  .home2-header-icon {
    height: 56px;
    width: 56px;
    margin-right: 10px;
  }

  .home2-header-subtitle {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    text-align: left;
    padding-top: 0px;
  }

  .home2-header-navigation {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .home2-header-navigation-menu-items {
    font-size: 16px;
  }

  .home2-header-clock {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 250px;
    font-size: 26px;
    line-height: 26px;
    font-family: "Roboto";
    text-align: right;
  }

  
  .home2-header-date {
    font-size: 14px;
    font-family: "Roboto";
    text-align: right;
  }