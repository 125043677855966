.webcam-view {
    width:800px;
    height:450px;
}

@media (max-width: 800px) {
    .webcam-view {
        width: 100%;
        height:calc(100vw * 0.56);
    }
  }
