.whirloff2 {
  background-color: #644109;
}

.whirloff2-full body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
  background-color: #644109;
}

.whirloff2-mobile {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 16px;
  color: black;
  background-color: #202020;
  width: 100%;
  justify-content: space-between;
}

.whirloff2-scroll-full-large {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: black;
  align-items: center;
  text-align: center;
  background-color: #FFFFFF;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  top: 146px;
  height: calc(100vh - 266px);
  width: 450px;
}

.whirloff2-scroll-full-xlarge {
  display: flex;
  flex-direction: column;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 18px;
  color: black;
  align-items: center;
  text-align: center;
  background-color: #FFFFFF;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  top: 146px;
  height: calc(100vh - 264px);
  width: 730px;
}

.whirloff2-body {
  justify-content: space-between;
  align-items: left;
  background-color: #202020;
  width: 1280px;
  margin-top: 6px;
  margin-bottom: 6px;
}

.whirloff2-header-full {
  position: fixed;
  left: 0%;
  top: 0%;
  width: 30.3%;
  height: 200px;
  background-color: #644109;
  color: #000000;
}

.whirloff2-header-large {
  position: absolute;
  left: 0vw;
  top: 0vh;
  width: 100%;
  height: 120px;
  background-color: #644109;
  color: #000000;
  margin-bottom: 1vh;
}

.whirloff2-header-mobile {
  background-color: #644109;
  width: 100%;
  height: 90px;
}


.whirloff2-sub-header-full {
  position: fixed;
  left: 0%;
  top: 200px;
  width: 30.3%;
  height: 80px;
  background-color: #000000;
  color: #FFFFFF;
  text-align: center;
  vertical-align: middle;
  font-size: 1.3em;
  line-height: 1.3em;
  padding: 1em;
  border: 2px;
  border-color: #FFFFFF;
  display:flex;
  justify-content: center;
  flex-direction: column;
}

.whirloff2-molen-list-full {
  position: fixed;
  width: 30.3%;
  top: 280px;
  height: calc(100vh - 390px);
  padding-left: 4px;
  padding-right: 4px;
  background-color: #202020;
  overflow: auto;
  scrollbar-width: thin;
}

.whirloff2-molen-list-large {
  position: absolute;
  width: 39%;
  top: 123px;
  height: calc(85vh - 233px);
  padding-left: 4px;
  padding-right: 4px;
  background-color: #202020;
  overflow: auto;
  scrollbar-width: narrow;
}

.whirloff2-molen-list-mobile {
  margin-left: 2px;
  margin-right: 2px;
  height: calc(100vh - 240px);
  background-color: #202020;
  overflow: auto;
  scrollbar-width: narrow;
}

.whirloff2-featured-mill-full {
  position: fixed;
  left: 30.5%;
  width: 69.5%;
  top: 50vh;
  height: 50vh;
  font-size: 26px;
  background-color: #644109;
}

.whirloff2-featured-mill-large {
  position: absolute;
  left: 0vw;
  width: 100%;
  top: 85vh;
  height: 65vh;
  font-size: 26px;
  background-color: #644109;
}

.whirloff2-featured-mill-mobile-info-container {
  position:absolute;
  left: 0vw;
  width: 100vw;
  top: calc(150vh - 50px);
  height: 60vh;
  padding: 6px;
}

.whirloff2-featured-mill-mobile-info-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 60vh;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  background-color: #303030;
  color: #FFFFFF;
  font-size: 0.9em;
  text-align: center;
}

.whirloff2-featured-mill-mobile-image {
  position: absolute;
  left: 0vw;
  width: 100vw;
  top: calc(210vh - 50px);
  height: 36vh;
  background-color: #202020;
}

.whirloff2-windy-map-full {
  position: fixed;
  left: 30.5%;
  top: 0px;
  width: 47.5%;
  height: calc(50vh - 3px);
  border: none;
}

.whirloff2-map-large {
  position: absolute;
  left: 39%;
  top: 123px;
  width: 61%;
  height: calc(85vh - 123px);
  border: none;
}

.whirloff2-map-mobile {
  position: absolute;
  left: 0%;
  top: calc(100vh - 50px);
  width: 100%;
  height: 50vh;
  border: none;
}

.whirloff2-map-bounds-links-full {
  width: 100%;
  height: 32px;
  background-color: #092f4f;
}

.whirloff2-map-inline {
  margin-top: 2px;
  width: 100%;
  height: 60vh;
}

.whirloff2-molen-table {
  width: 100%;
}


.whirloff2-headline-stats-full {
  position: fixed;
  left: 78%;
  width: 22%;
  top: 0%;
  height: 50%;
  background-color: #202020;
}

.whirloff2-headline-stats-large {
  position: absolute;
  left: 0vw;
  width: 39%;
  top: calc(85vh - 110px);
  height: 110px;
  background-color: #202020;
}

.whirloff2-headline-stats-mobile {
  position: absolute;
  left: 0vw;
  width: 100vw;
  top: calc(100vh - 150px);
  height: 100px;
  background-color: #202020;
}

.whirloff2-footer-full {
  position: fixed;
  top: calc(100% - 110px);
  height: 110px;
  width: 30vw;
  font-size: 1.6em;
  line-height: 1.2em;
  background-color: #303030;
  margin-top: 4px;
  width: 100%;
  text-align: left;
  color: #FFFFFF;
  padding-left: 10px;
  padding-right: 10px;
}

.whirloff2-footer-large {
  position: absolute;
  top: 150vh;
  height: 110px;
  left: 0vw;
  width: 100vw;
  font-size: 20px;
  line-height: 24px;
  background-color: #404040;
  width: 100%;
  text-align: left;
  color: #FFFFFF;
  padding-left: 10px;
  padding-right: 10px;
}

.whirloff2-footer-mobile {
  position: absolute;
  top: calc(245vh - 48px);
  height: 120px;
  left: 0vw;
  width: 100vw;
  font-size: 16px;
  line-height: 24px;
  background-color: #B3C8D8;
  margin-top: 0px;
  width: 100%;
  text-align: left;
  color: black;
  padding-left: 10px;
  padding-right: 10px;
}


.whirloff2-lower {
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  background-color: #505050;
  padding: 4px;
}

.whirloff2-text {
  font-size: 14px;
  line-height: 20px;
  padding: 10px;
}

.whirloff2-text a {
  color: blue;
}

.whirloff2-text a:visited {
  color: blue;
}

@media (max-width: 1280px) {
  .whirloff2-footer-full {
    margin-top: 3px;
    padding: 6px;
    font-size: 16px;
    line-height: 20px;
    width: 100%;
  }
}