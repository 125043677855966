
.current-situation-panel-body-lower {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;
    justify-content:space-between;
}


.orientation-diagram-panel {
    flex-flow: row;
    width:300px;
    height:300px;
    padding: 6px;
}

@media (max-width: 400px) {
    .orientation-diagram-panel {
        padding: 4px;
    }
}

.wind-data-panel {
    width: 50%;
    min-width: 140px;
    background-color: #F0F0f0;
    color: #000000;
    padding:10px;
    margin-right:10px;
}

.wind-data-panel-gust {
    background-color: #000000;
    color: #FFFFFF;
    padding:4px;
}


@media (max-width: 600px) {
    .wind-data-panel {
        min-width: 140;
        padding: 4px;
        margin-right: 6px;
    }
}

.mill-facing-panel {
    width: 50%;
    min-width: 180;
    background-color: #F0F0F0;
    color: #F0F0F0;
    padding: 10px;
}

@media (max-width: 600px) {
    .mill-facing-panel {
        min-width: 140;
        padding: 4px;
    }
  }
