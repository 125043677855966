html, body {
    min-height: 100vh;
}

.widget-container {
    font-family: Open Sans;
    font-size: 16px;
    line-height:20px;
    height: 100%;
    padding: 4px;
    background-color: #0f4c81;
    color: white;
  }

  @media (max-width: 600px) {
      .widget-container {
        font-size: 13px;
        line-height:26px;
      }
  }

  .widget-footer {
    display: flex;
    margin: 6px;
    padding-top: 4px;
    padding-bottom: 12px;
}


.widget-body {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0px;
    margin-bottom:0px;
    width:100%;
}
