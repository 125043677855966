@import url('https://fonts.googleapis.com/css?family=Raleway');
@import url('https://fonts.googleapis.com/css?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto');

html {
  width: 100%;
  max-height:100vh;
  height:100vh;
  overflow: auto;
  margin: 0;
  padding: 0;
}

body, #root, .App {
  width: 100%;
  background-color: #202020;
  color: #FFFFFF;
  overflow: auto;
  height:100%;
  max-height:100vh;

    /* scale factors required to support uncontrollable browser zooming in kiosk mode */
    --iframe-zoom: 100%;
    --iframe-scale: 1;
    --fixed-size-item-scale: 1.0;  
}

.app-header-text {
  font-family: "Raleway";
  font-weight: bold;
  font-size:28px;
  line-height:38px;
}

@media (max-width: 300px) {
  .app-header-text {
    font-size:24px;
    line-height: 28px;
  }
}

.app-standard-text {
  font-family: "Open Sans";
  font-size:14px;
  line-height:16px;
}


.app-supersmalltext {
  font-family: "Open Sans";
  font-size: 11.5px;
  line-height: 12px;
}

@media (max-width: 300px) {
  .app-supersmalltext {
    font-size: 10px;
    line-height: 10.5px;
    }
}

@media (min-width: 560px) and (max-width: 759px) {
  .app-supersmalltext {
    font-size: 10px;
    line-height: 10.5px;
    }
}


.app-smalltext {
    font-family: "Open Sans";
    font-size: 13px;
    line-height: 15px;
}

@media (min-width: 560px) and (max-width: 759px) {
  .app-smalltext {
    font-size: 12px;
    line-height: 13px;
    }
}

.app-smalltext a {
  color: white;
}

.app-smalltext a:visited {
  color: white;
}



.app-panel-header-text {
  font-family: "Roboto";
  font-size: 16px;
  line-height: 20px;
}

@media (min-width: 560px) and (max-width: 759px) {
  .app-panel-header-text {
    font-size:14px;
    line-height: 16px;
  }
}


.app-oversize-text {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  display: inline;
  padding-right:10px;
}

@media (min-width: 560px) and (max-width: 759px) {
  .app-oversize-text {
    font-size:28px;
  }
}


table.table-small {
  height:12px;
  font-family: "Open Sans";
  font-size: 12px;
}

table.table-small tr:nth-child(even) {background-color: #092f4f;}
table.table-small tr:nth-child(odd) {background-color: #0f4c81;}

table.table-small th {
  height: 35px;
  font-family: "Open Sans";
  font-weight:normal;
  font-size: 12px;
  padding-left:2px;
  padding-right:2px;
  text-align:center;
}

table.table-small tr td {
  height: 18px;
  border: 2px solid #092f4f;
  padding-left:5px;
  padding-right:5px;
  font-family: "Open Sans";
  font-size: 12px;
  padding-top: 1px;
  padding-bottom: 1px;
}

