  
  table.whirloff-table {
    height:20px;
    font-family: "Open Sans";
    font-size: 1.4em;
    width: 100%;
  }
  
  
  @media (max-width: 1399px) {
    table.whirloff-table {
     font-size: 1.2em;
   }
 }
  table.whirloff-table tr:nth-child(even) {background-color: #202020;}
  table.whirloff-table tr:nth-child(odd) {background-color: #303030;}
  
  table.whirloff-table th {
    height: 2.1em;
    font-family: "Roboto";
    font-weight:bold;
    padding-left:5px;
    padding-right:5px;
    text-align:right;
    background-color: #202020;
    color: #FFFFFF;
  }
  
  table.whirloff-table tr td {
    height: 2.1em;
    color: #FFFFFF;
    padding-left:5px;
    padding-right:5px;
    font-family: "Roboto";
    padding-top: 1px;
    padding-bottom: 1px;
  }
  
  .whirloff-table-mill-name {
    font-family: "Raleway";
  }

  
@media (max-width: 576px) {

  table.whirloff-table {
    font-size: 13px;
  }

  table.whirloff-table th {
    height: 28px;
    padding-left:3px;
    padding-right: 3px;
    text-align:right;
    background-color: #202020;
    color: #FFFFFF;
  }
  
  table.whirloff-table tr td {
    height: 28px;
    color: #FFFFFF;
    padding-left:5px;
    padding-right:5px;
    padding-top: 1px;
    padding-bottom: 1px;
  }
  
  .whirloff-table-mill-name {
    font-family: "Raleway";
  }
}
