.wind-warnings-container {
  width: 100% - 12px;
  padding: 6px;
  background-color: #041828;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 6px;
  margin-top: 10px;
  border-color: #FF0000;
  border-width: 4px;
  border-style: solid;
  font-size: 14px;
}

@media (max-width: 300px) {
  .wind-warnings-container {
    font-size: 12px;
    line-height:14px;
    border-width: 2px;
  }
}


.wind-warning-left {
  padding: 6px;
}

.wind-warning-right {
  margin-left: 12px;
}

@media (max-width: 300px) {
  .wind-warning-right {
    margin-left:8px;
  }
}


.wind-warning-icon {
  height: 60px;
  width: 60px;
}

@media (max-width: 300px) {
  .wind-warning-icon {
    height: 48px;
    width: 48px;
  }  
}