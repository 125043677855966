.vcs-background {
  position:fixed;
  height:100%;
  width:100%;
  font-family: "Open Sans";
  background-color: #e0e0e0;
}
 
.vcs-content {
  font-size:1.6vw;
  font-weight: lighter;
  color:white;
  text-align: center;
  justify-items: center;
}

.vcs-live-weather {
  position:fixed;
  top:3%;
  left:42%;
  background-color: #253858;
  width: 55%;
  height: 9%;
  color: white;
  border-style:solid;
  border-color:white;
  border-width: 2px;
}

.vcs-welcome {
  position:fixed;
  top:2%;
  left:1%;
  width: 37%;
  height: 45%;
}


.vcs-motd {
  position:fixed;
  top:57%;
  left:2%;
  background-color: #253858;
  width: 36%;
  height: 33%;
  padding:12px;
  border-style:solid;
  border-color:white;
  border-width: 2px;
}



.vcs-events {
  position:fixed;
  top:16%;
  left:42%;
  background-color: #253858;
  width: 26%;
  height: 30%;
  padding:12px;
  border-style:solid;
  border-color:white;
  border-width: 2px;
}

.vcs-activities {
  position:fixed;
  top:50%;
  left:42%;
  background-color: #108020;
  width: 26%;
  height: 40%;
  padding:12px;
  border-style:solid;
  border-color:white;
  border-width: 2px;
}

.vcs-facebook {
  position:fixed;
  top:16%;
  left:71%;
  background-color: #909090;
  width: 26%;
  height: 73%;
  padding:12px;
  border-style:solid;
  border-color:white;
  border-width: 2px;
}


.vcs-footer {
  position:fixed;
  top:93%;
  left:0%;
  background-color: #253858;
  width: 100%;
  height: 7vh;
  color: white;
  padding:10px;
  border-style:solid;
  border-color:white;
  border-width: 0px;
  border-right: none;
  border-left: none;
  border-bottom: none;
}
