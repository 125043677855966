.sensor-gateway-stats-panel-contents {
    display: flex;
    flex-direction: column;
    width:100%;
}

table#sensor-gateway-stats-table {
    height:12px;
    font-family: "Open Sans";
    font-size: 12px;
    width:100%;
}

#sensor-gateway-stats-table tr:nth-child(even) {background-color: #e0e0e0;}
#sensor-gateway-stats-table tr:nth-child(odd) {background-color: #ffffff;}

#sensor-gateway-stats-table tr th {
    height: 33px;
    background-color: #ffffff;
    font-family: "Roboto";
    font-weight:bold;
    font-size: 15px;
    padding-left:2px;
    padding-right:2px;
    text-align:left;
}

#sensor-gateway-stats-table tr td {
    height: 33px;
    border: 1px solid #909090;
    padding-left:5px;
    padding-right:5px;
    font-family: "Open Sans";
    font-size: 12px;
    line-height:14px;
    padding-top: 0px;
    padding-bottom: 0px;
}
