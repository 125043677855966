.panel-container {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0px;
    margin-bottom:0px;
    width:100%;
}

.panel-wide {
    background-color: #092F4F;
    padding:6px;
    margin:6px;
    width:800px;
}

.panel-super-wide {
    background-color: #092F4F;
    padding:6px;
    margin:6px;
    width:100%;
}


.panel-narrow {
    background-color: #092F4F;
    padding:6px;
    margin:6px;
    width:360px;
    max-width: 360px;
}

@media (max-width: 559px) {
    .panel-wide {
        padding:4px;
        margin:4px;
        width:100%;
        min-width:290px;
    }
    .panel-narrow {
        width: 100%;
        padding:4px;
        margin:2px;
        margin-top:4px;
        margin-bottom:4px;
     }
}

@media (min-width: 560px) and (max-width: 719px) {
    .panel-wide {
       padding:4px;
       margin:2px;
       margin-top:6px;
       margin-bottom:6px;
       width:100%;
       min-width:290px;
    }

  .panel-narrow {
      padding:4px;
      margin:2px;
      margin-top:6px;
      margin-bottom:6px;
      width:49%;
      min-width: 250px;
   }
}

.panel-header {
    height:20px;
    font-family: "Roboto";
    font-weight:normal;
    font-size: 20px;
    display: flex;
    flex-direction:row;
    align-items: stretch;
    justify-content:space-between;
}

@media (max-width: 559px) {
    .panel-header {
        font-size: 18px;
    }
  }

@media (min-width: 560px) and (max-width: 719px) {
    .panel-header {
        font-size: 16px;
    }
  }

.panel-header-left {
    font-family: "Roboto";
    font-weight:normal;
}

.panel-header-right {
    font-family: "Roboto";
    font-weight: normal;
}

.panel-body {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 6px;
    width:100%;
}

@media (max-width: 559px) {
    .panel-body {
        padding:0px;
    }
  }

  .panel-body-light {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 6px;
    width:100%;
    background-color: white;
    color: #303030;
}

@media (max-width: 559px) {
    .panel-body-light {
        padding:0px;
    }
  }

.panel-columns {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0px;
    margin-bottom:0px;
    width:100%;
}

.panel-columns-span-width {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;
    justify-content:space-between;
}