  .whirloff-featured-mill-container {
    display: flex;
    flex-direction: row;
    padding: 8px;
    justify-content: space-between;
    align-items: center;
    background-color: #202020;
    color: #FFFFFF;
    margin: 8px;
    font-size: 0.9em;
    height: calc(100% - 16px);
    width: calc(100% - 16px);
  }

  @media (max-width: 1399px) {
     .whirloff-featured-mill-container {
      font-size: 1em;
    }
  }

  .whirloff-featured-mill-header-turning {
    background-color: #00A000;
    color: #FFFFFF;
    margin-bottom: 12px;
    font-size: 1.2em;
    line-height: 1.2em;
    font-weight: bold;
    width: 100%;
    padding: 4px;
  }

  .whirloff-featured-mill-header-not-turning {
    background-color: #644109;
    color: #FFFFFF;
    margin-bottom: 12px;
    font-size: 1.2em;
    line-height: 1.2em;
    font-weight: bold;
    width: 100%;
    padding: 4px;
  }

  .whirloff-featured-mill-left {
    display: flex;
    flex-direction: column;
    width: 38%;
    height: 100%;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    background-color: #303030;
    color: #FFFFFF;
    margin-top: 0px;
    font-size: 0.8em;
    line-height: 1.2em;
    padding-top: 0px;
  }

  .whirloff-featured-mill-name-panel {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding-left: 12px;
    padding-right: 12px;
  }

  .whirloff-featured-mill-name-panel-left-graphic {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100px;
    padding-top: 4px;
  }

  .whirloff-featured-mill-name-panel-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    flex-grow: 1;
    margin-left: 6px;
    font-size: 0.8em;
    line-height: 1.1em;
  }

  .whirloff-featured-mill-name-panel-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
  }


  .whirloff-featured-mill-name {
    font-family: "Raleway";
    font-weight: bold;
    font-size: 1.4em;
    line-height: 1.2em;
    margin-top: 6px;
  }


  .whirloff-featured-mill-stats-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30%;
    justify-content: space-between;
    padding: 10px;
  }

  .whirloff-featured-mill-stats-left {
    width: calc(50% - 5px);
    height: 100%;
    background-color: #202020;
    padding: 6px;
    display:flex;
    flex-direction: column;
    justify-content:space-evenly;
  }

  .whirloff-featured-mill-stats-right {
    width: calc(50% - 5px);
    background-color: #202020;
    padding: 6px;
    display:flex;
    flex-direction: column;
    justify-content:space-evenly;
    align-items: center;
  }

  .whirloff-featured-mill-stats-title {
    font-size: 0.9em;
    font-weight: bold;
    height: 1.5em;
  }

  .whirloff-featured-mill-stats-content {
    margin-top: 0.6em;
    font-size: 100%;
    height: 4em;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: center;
  }


  .whirloff-featured-mill-rotation-count {
    font-family: "Roboto";
    font-weight: bold;
    font-size: 2.0em;
    line-height: 2.2em;
  }

  @media (max-width: 720px) {
    .whirloff-featured-mill-rotation-count {
     font-size: 1.6em;
   }
 }

  @media (max-width: 1400px) {
    .whirloff-featured-mill-rotation-count {
     font-size: 1.8em;
   }
 }

 .whirloff-featured-mill-wind-direction-arrow {
    margin-left: 10px;
  }

  .whirloff-featured-mill-wind-direction-compass-point {
    font-size: 1.4em;
    line-height: 1em;
    font-weight:bold;
  }

  .whirloff-featured-mill-right {
    width: 61%;
    height: 100%;
    font-size: 12px;
    align-content:center;
    display: flex;
    flex-direction: column;
  }

  .whirloff-featured-mill-image-container {
    height: calc(100% - 12px);
    background-color: #404040;
  }

  @media (max-width: 720px) {
    .whirloff-featured-mill-photo-copyright {
      height: calc(100% - 20px);
    }
 }

  img.whirloff-featured-mill-image {
    height: 100%;
    object-fit: cover;
  }

  .whirloff-featured-mill-data-source {
    display: flex;
    flex-direction: row;
    text-align: center;
    color: #A0A0A0;
    font-size: 0.8em;
    margin-bottom: 8px;
  }

  .whirloff-featured-mill-photo-copyright {
    font-size: 12px;
    text-align: right;
    color: #A0A0A0;
  }

  @media (max-width: 720px) {
    .whirloff-featured-mill-photo-copyright {
     font-size: 0.6em;
   }
 }
