html, body {
    min-height: 100vh;
}

.tech-container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    font-family: Open Sans;
    font-size: 16px;
    line-height:20px;
    padding: 4px;
    background-color: #0f4c81;
    color: white;

    width: var(--iframe-zoom);
    height: var(--iframe-zoom);
    transform:scale(var(--iframe-scale));
    transform-origin: 0% 0% 0px;
  }

 .tech-header {
      font-family: "Raleway";
      display: flex;
      flex-direction: row;
      align-items: center;
      width:100%;
      height:72px;
      padding:6px;
  }
  
.tech-icon {
    width: 70px;
}

.tech-body {
    flex: 1;
    flex-shrink: 0;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0px;
    margin-bottom:0px;
    min-width: 100%;
}

  .tech-footer {
    flex-shrink: 0;
    margin: 6px;
}


.tech-title {
    font-size:20px;
    padding-left: 10px;
}
