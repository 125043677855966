.vcs-background {
  position:fixed;
  height:100%;
  width:100%;
  font-family: "Open Sans";
  background-color: #d0d0d0;
}
 
.vcs-screen-test {
  position:fixed;
  top:2%;
  left:2%;
  background-color: #253858;
  width: 96%;
  height: 96%;
  color: white;
  padding:1%;
  vertical-align: middle;
  border-style:solid;
  border-color:red;
  border-width: 200%;
}
