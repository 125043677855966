.console {
  background-color: #ffffff;
  font-family: "Open Sans";
  font-size: 1vh;
  line-height: 18px;
  color: black;
}

.console-windy-iframe {
  position: fixed;
  left: 0%;
  top: 0%;
  width: 67%;
  height: 100%;
}

.console-live-panel {
  position: fixed;
  left: 44%;
  top: 82.5%;
  width: 22%;
  height: 12%;
  background-color: #C00000;
  padding: 0.5vh;
  font-size: 2.4vh;
  font-family: "Open Sans";
}

.console-live-panel-top {
  display: flex;
  flex-direction: row;
  align-items:baseline;
}

.console-live-panel-clock {
  font-size: 2vh;
}

.console-live-panel-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.3vh;
  margin-top: 1vh;
}

.console-live-panel-qr-code {
  position: fixed;
  left: 59.75%;
  top: 83.5%;
  width: 10vh;
  height: 10vh;
  background-color: #FFFFFF;
  justify-content: center;
  align-items: center;
  display: flex;
}

.console-live-title {
  font-size: 2.4vh;
  font-family: "Open Sans";
  margin-left: 1vh;
  margin-right: 1vh;
}

.console-right-panel {
  background-color: #FFFFFF;
  color: #000000;
  position: fixed;
  left: 67%;
  top: 0%;
  width: 33%;
  height: 100%;
  padding: 0.4vh;
}

.console-right-header {
  position: fixed;
  left: 67%;
  top: 0%;
  width: 33%;
  height: 10%;
  background-color: #0f4c81;
  color: #FFFFFF;
  padding: 0.4vh;
  display: flex;
  align-items: center;
}

.console-header-container {
  font-family: "Raleway";
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding-left: 1vh;
  justify-content: space-between;
}

.console-header-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.console-header-left {
  display: flex;
  flex-direction: row;
  align-items: center;
}


.console-header-icon {
  height: 7vh;
  width: auto;
}

.console-header-text {
  font-weight: bold;
  font-size: 3vh;
}

.console-header-title {
  font-family: 'Open Sans';
  padding-left: 10px;
  font-size: 1.8vh;
}

.console-right-body {
  position: fixed;
  left: 67%;
  top: 10%;
  width: 33%;
  height: 71%;
  padding: 0.6vh;
}

.console-right-body-heading {
  font-size: 1.6vh;
  color: #FFFFFF;
  text-align: center;
}

.console-right-footer {
  position: fixed;
  left: 67%;
  top: 86%;
  width: 33%;
  height: 14%;
  padding: 0.5vh;
  font-size: 1.8vh;
  background-color: #B3C8D8;
}

.console-right-footer-copyright {
  font-size: 1.3vh;
}

.console-right-footer-container {
  font-family: "Open Sans";
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 2vh;
  padding-right: 1vh;
}

.console-right-footer-fuw-logo {
  height: 13vh;
  width: auto;
}