  .widget-demo {
    background-color: #B0B0B0;
    display: flex;
    margin: 10px;
    padding: 10px;
    flex-wrap: wrap;
}

.widget-demo-column {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin: 10px;
    padding: 10px;
    flex-wrap: wrap;
}