.dw3-wind-rose {
    position:fixed;
    top: 12vh;
    left: 2vw;
    width: 96vw;
    height:86vh;
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
    color:#000000;
    padding: 2vw;
}

.dw3-wind-rose-explanation {
    margin-left:5vw;
}

.dw3-beauf-force-number {
    text-align: center;
}

.dw3-milling-condition-text {
    padding-left: 0.6vw;
}