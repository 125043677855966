
.current-situation-panel-body-lower {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    width: 100%;
    justify-content:space-between;
}

.sail-table-large-numbers {
  font-family: "Roboto";
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  display: inline;
  padding-right: 6px;
}

.sail-rotation-diagram-panel {
    flex-flow: row;
    width:100%;
    height:205px;
    padding: 6px;
    display:flex;
    flex-direction: row;
}

.sail-rpm-panel {
  padding-left: 20px;
}

.sail-wind-panel {
  display:flex;
  flex-direction: row;
  margin-top: 6px;
}

.sail-wind-right-box {
  margin-left: 6px;
}

.sail-wind-direction {
  font-size: 20px;
  font-weight: bold;
  line-height: 26px;
}


@media (max-width: 400px) {
    .orientation-diagram-panel {
        padding: 4px;
    }
}

.brake-status-panel {
    width:100%;
    background-color: #000000;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 4px;
    text-align: center;
    vertical-align: middle;
    font-size: 18px;
}

.wind-data-panel {
    width: 50%;
    min-width: 140px;
    background-color: #F0F0f0;
    color: #000000;
    padding:10px;
    margin-right:10px;
}

@media (max-width: 600px) {
    .wind-data-panel {
        min-width: 140;
        padding: 4px;
        margin-right: 6px;
    }
}

.mill-facing-panel {
    width: 50%;
    min-width: 180;
    background-color: #F0F0F0;
    color: #F0F0F0;
    padding: 10px;
}

@media (max-width: 600px) {
    .mill-facing-panel {
        min-width: 140;
        padding: 4px;
    }
  }

  .blink {
    animation: blink 1s step-start 0s infinite;
    -webkit-animation: blink 1s step-start 0s infinite;
  }
  
  @keyframes blink {
    50% {
      opacity: 0.0;
    }
  }
  @-webkit-keyframes blink {
    50% {
      opacity: 0.0;
    }
  }

  .sail-table-major-text {
    font-family: "Roboto";
    font-size: 16px;
    line-height: 16px;
    display: inline;
  }

  .sail-turns-panel-contents {
    display: flex;
    flex-direction: column;
    width:100%;
}

table#sail-turns-table {
    height:60px;
    font-family: "Open Sans";
    font-size: 16px;
    line-height:18px;
    width:100%;
    margin-top:8px;
}


#sail-turns-table tr th {
    font-family: "Roboto";
    font-weight:normal;
    font-size: 14px;
    line-height: 16px;
    padding-left:2px;
    padding-right:0px;
    text-align:center;
}


#sail-turns-table tr td {
    height: 38px;
    border: 2px solid #ffffff;
    padding-left:5px;
    padding-right:2px;
    font-family: "Open Sans";
    font-size: 13px;
    padding-top: 0px;
    padding-bottom: 0px;
}

@media (max-width: 300px) {
    #sail-turns-table tr td {
        height: 29px;
        border: 1px solid #092f4f;
        padding-left:1px;
        padding-right:1px;
        font-family: "Open Sans";
        font-size: 13px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
  }

@media (min-width: 560px) and (max-width: 719px) {
    #sail-turns-table tr td {
        height: 29px;
        border: 1px solid #092f4f;
        padding-left:1px;
        padding-right:1px;
        font-family: "Open Sans";
        font-size: 13px;
        padding-top: 0px;
        padding-bottom: 0px;
    }
  }

